import React, { useContext } from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'
import { PhoneContext } from './context/PhoneContext'

const PhoneImg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.487"
    height="25.507"
    viewBox="0 0 25.487 25.507"
  >
    <defs>
      <style></style>
    </defs>
    <path
      fill="#fff"
      className="a"
      d="M28.571,27.916a2.5,2.5,0,0,0,0-3.69L25.6,21.251a2.485,2.485,0,0,0-1.831-.83,2.581,2.581,0,0,0-1.831.83l-1.717,1.717c-.143-.086-.286-.143-.429-.229-.2-.086-.372-.2-.544-.286a18.2,18.2,0,0,1-4.434-4.034A9.834,9.834,0,0,1,13.351,16.1c.429-.4.858-.83,1.259-1.23l.458-.458a2.679,2.679,0,0,0,.858-1.86,2.613,2.613,0,0,0-.858-1.86L13.609,9.207c-.172-.172-.343-.343-.486-.515-.315-.343-.658-.687-1-1a2.55,2.55,0,0,0-1.8-.8,2.665,2.665,0,0,0-1.831.8L6.628,9.55a3.917,3.917,0,0,0-1.173,2.489,9.975,9.975,0,0,0,.687,4.005,23.154,23.154,0,0,0,4.12,6.895A25.477,25.477,0,0,0,18.7,29.547a12.737,12.737,0,0,0,4.749,1.4h.343a4.029,4.029,0,0,0,3.09-1.345l.029-.029a10.054,10.054,0,0,1,.944-.973A5.374,5.374,0,0,0,28.571,27.916ZM26.883,27.6a12.444,12.444,0,0,0-1.03,1.087,2.563,2.563,0,0,1-2.031.858h-.257A12.7,12.7,0,0,1,19.33,28.26a24,24,0,0,1-7.953-6.237,22.227,22.227,0,0,1-3.891-6.466,7.468,7.468,0,0,1-.6-3.376,2.616,2.616,0,0,1,.744-1.6L9.46,8.749a1.2,1.2,0,0,1,.83-.4,1.211,1.211,0,0,1,.83.4c.343.315.629.629.973.973l.515.515L14.066,11.7a1.236,1.236,0,0,1,.429.858,1.236,1.236,0,0,1-.429.858l-.458.458c-.458.458-.887.887-1.345,1.316l-.029.029a1.1,1.1,0,0,0-.286,1.2c0,.029,0,.029.029.057a11.188,11.188,0,0,0,1.745,2.832A19.86,19.86,0,0,0,18.5,23.654c.229.143.458.257.658.372.2.086.372.2.544.286.029,0,.029.029.057.029a1.166,1.166,0,0,0,.515.143,1.151,1.151,0,0,0,.8-.372l1.86-1.86a1.03,1.03,0,0,1,1.631,0l2.975,2.975a1.1,1.1,0,0,1-.029,1.717A6.685,6.685,0,0,1,26.883,27.6ZM18.644,10.637a.68.68,0,0,1,.83-.572,8.075,8.075,0,0,1,4.492,2.346A8.662,8.662,0,0,1,26.311,16.9a.72.72,0,0,1-.572.83h-.114a.735.735,0,0,1-.715-.6,6.726,6.726,0,0,0-1.945-3.719,7.014,7.014,0,0,0-3.719-1.945A.681.681,0,0,1,18.644,10.637Zm10.871,6.294a12.412,12.412,0,0,0-3.433-6.637,12.219,12.219,0,0,0-6.637-3.433.68.68,0,0,1-.572-.83.693.693,0,0,1,.83-.572A13.7,13.7,0,0,1,30.917,16.673a.72.72,0,0,1-.572.83H30.23A.684.684,0,0,1,29.515,16.931Z"
      transform="translate(-5.437 -5.442)"
    />
  </svg>
)

const PhoneLink = props => {
  const {
    state: { e164, local },
  } = useContext(PhoneContext)
  return (
    <StyledPhoneLink>
      <div className="tel-container">
        <a href={`tel:${e164}`} {...props}>
          <span className="tel-tablet">
            <PhoneImg />
          </span>
          <span className="tel-desktop">{local}</span>
        </a>
      </div>
    </StyledPhoneLink>
  )
}

const StyledPhoneLink = styled.section`
  .tel-tablet {
    display: none;
  }

  .tel-container {
    background-color: white;
    padding: 8px 12px;
    border-radius: 20em;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .tel-tablet {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      background-color: ${colors.brand.normal};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tel-desktop {
      display: none;
    }
  }
`
export default PhoneLink
export { StyledPhoneLink }
