// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cgv-jsx": () => import("./../../../src/pages/cgv.jsx" /* webpackChunkName: "component---src-pages-cgv-jsx" */),
  "component---src-pages-commande-jsx": () => import("./../../../src/pages/commande.jsx" /* webpackChunkName: "component---src-pages-commande-jsx" */),
  "component---src-pages-comment-ca-marche-jsx": () => import("./../../../src/pages/comment-ca-marche.jsx" /* webpackChunkName: "component---src-pages-comment-ca-marche-jsx" */),
  "component---src-pages-connexion-jsx": () => import("./../../../src/pages/connexion.jsx" /* webpackChunkName: "component---src-pages-connexion-jsx" */),
  "component---src-pages-creer-un-compte-jsx": () => import("./../../../src/pages/creer-un-compte.jsx" /* webpackChunkName: "component---src-pages-creer-un-compte-jsx" */),
  "component---src-pages-demande-adresse-jsx": () => import("./../../../src/pages/demande/adresse.jsx" /* webpackChunkName: "component---src-pages-demande-adresse-jsx" */),
  "component---src-pages-demande-boissons-jsx": () => import("./../../../src/pages/demande/boissons.jsx" /* webpackChunkName: "component---src-pages-demande-boissons-jsx" */),
  "component---src-pages-demande-budget-jsx": () => import("./../../../src/pages/demande/budget.jsx" /* webpackChunkName: "component---src-pages-demande-budget-jsx" */),
  "component---src-pages-demande-budget-specifique-jsx": () => import("./../../../src/pages/demande/budget-specifique.jsx" /* webpackChunkName: "component---src-pages-demande-budget-specifique-jsx" */),
  "component---src-pages-demande-code-promo-jsx": () => import("./../../../src/pages/demande/code-promo.jsx" /* webpackChunkName: "component---src-pages-demande-code-promo-jsx" */),
  "component---src-pages-demande-convives-jsx": () => import("./../../../src/pages/demande/convives.jsx" /* webpackChunkName: "component---src-pages-demande-convives-jsx" */),
  "component---src-pages-demande-date-jsx": () => import("./../../../src/pages/demande/date.jsx" /* webpackChunkName: "component---src-pages-demande-date-jsx" */),
  "component---src-pages-demande-debut-jsx": () => import("./../../../src/pages/demande/debut.jsx" /* webpackChunkName: "component---src-pages-demande-debut-jsx" */),
  "component---src-pages-demande-duree-jsx": () => import("./../../../src/pages/demande/duree.jsx" /* webpackChunkName: "component---src-pages-demande-duree-jsx" */),
  "component---src-pages-demande-email-jsx": () => import("./../../../src/pages/demande/email.jsx" /* webpackChunkName: "component---src-pages-demande-email-jsx" */),
  "component---src-pages-demande-format-jsx": () => import("./../../../src/pages/demande/format.jsx" /* webpackChunkName: "component---src-pages-demande-format-jsx" */),
  "component---src-pages-demande-merci-jsx": () => import("./../../../src/pages/demande/merci.jsx" /* webpackChunkName: "component---src-pages-demande-merci-jsx" */),
  "component---src-pages-demande-noms-jsx": () => import("./../../../src/pages/demande/noms.jsx" /* webpackChunkName: "component---src-pages-demande-noms-jsx" */),
  "component---src-pages-demande-options-jsx": () => import("./../../../src/pages/demande/options.jsx" /* webpackChunkName: "component---src-pages-demande-options-jsx" */),
  "component---src-pages-demande-participants-jsx": () => import("./../../../src/pages/demande/participants.jsx" /* webpackChunkName: "component---src-pages-demande-participants-jsx" */),
  "component---src-pages-demande-phone-jsx": () => import("./../../../src/pages/demande/phone.jsx" /* webpackChunkName: "component---src-pages-demande-phone-jsx" */),
  "component---src-pages-demande-regime-jsx": () => import("./../../../src/pages/demande/regime.jsx" /* webpackChunkName: "component---src-pages-demande-regime-jsx" */),
  "component---src-pages-demande-societe-jsx": () => import("./../../../src/pages/demande/societe.jsx" /* webpackChunkName: "component---src-pages-demande-societe-jsx" */),
  "component---src-pages-demande-source-jsx": () => import("./../../../src/pages/demande/source.jsx" /* webpackChunkName: "component---src-pages-demande-source-jsx" */),
  "component---src-pages-demande-type-de-banquet-jsx": () => import("./../../../src/pages/demande/type-de-banquet.jsx" /* webpackChunkName: "component---src-pages-demande-type-de-banquet-jsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mentions-legales-jsx": () => import("./../../../src/pages/mentions-legales.jsx" /* webpackChunkName: "component---src-pages-mentions-legales-jsx" */),
  "component---src-pages-merci-jsx": () => import("./../../../src/pages/merci.jsx" /* webpackChunkName: "component---src-pages-merci-jsx" */),
  "component---src-pages-nos-clients-jsx": () => import("./../../../src/pages/nos-clients.jsx" /* webpackChunkName: "component---src-pages-nos-clients-jsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-traiteurs-evenementiel-jsx": () => import("./../../../src/pages/traiteurs-evenementiel.jsx" /* webpackChunkName: "component---src-pages-traiteurs-evenementiel-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */),
  "component---src-templates-category-zip-jsx": () => import("./../../../src/templates/category-zip.jsx" /* webpackChunkName: "component---src-templates-category-zip-jsx" */),
  "component---src-templates-menu-jsx": () => import("./../../../src/templates/menu.jsx" /* webpackChunkName: "component---src-templates-menu-jsx" */),
  "component---src-templates-pg-menu-jsx": () => import("./../../../src/templates/pg-menu.jsx" /* webpackChunkName: "component---src-templates-pg-menu-jsx" */),
  "component---src-templates-seo-cities-caters-jsx": () => import("./../../../src/templates/seo-cities-caters.jsx" /* webpackChunkName: "component---src-templates-seo-cities-caters-jsx" */)
}

