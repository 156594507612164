import React from 'react'
import { Link as GatsbyLink, navigate as gatsbyNav } from 'gatsby'
import { getQueryParams } from '../util'

const Link = ({
  keepQueryParams = true,
  forceExternal = false,
  withPrefix = false, // mainly to use with forceExternal when opening a link into a new tab but on the same prefixed url for example.
  to,
  children,
  ...props
}) => {
  if (!to) {
    return children
  }

  const external = /https?:\/\//.test(to) || props.target === '_blank'
  to = `${to}${keepQueryParams ? getQueryParams().queryString : ''}`
  if (external || forceExternal) {
    if (withPrefix) {
      to = `${process.env.GATSBY_PATH_PREFIX || '/'}/${to}`.replace(/\/+/g, '/')
    }
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  } else {
    return (
      <GatsbyLink to={to} {...props}>
        {children}
      </GatsbyLink>
    )
  }
}

const navigate = (to, { keepQueryParams = true, ...options } = {}) => {
  if (typeof to === 'number') {
    window && window.history.go(to)
  } else {
    const external = /https?:\/\//.test(to)
    to = `${to}${keepQueryParams ? getQueryParams().queryString : ''}`
    if (external && typeof window !== undefined) {
      if (options.newTab) {
        window.open(to)
      } else {
        window.location = to
      }
    } else {
      gatsbyNav(to, options)
    }
  }
}

export default Link
export { navigate }
